<!--
 功能名称:boss-选择门店
 开发人:梁高权
 创建时间:2021/03/17
 最后修改时间:2021/03/17
-->

<template>
  <div class="store-message-wrap" v-if="show">
    <empty-box v-if="boss.storeList.length==0"></empty-box>
    <ul v-for="(item,index) in boss.storeList" :key="index" class="store-content" @click="chooseShopItem(item)">
        <li>
            <h4 class="store-name">{{item.shop_name}}</h4>
            <p class="store-address" v-if="!!item.storeAds">地址：{{item.address_detail}}</p>
            <van-icon name="checked" v-if="userStatus.curs_node_id_2.substring(0,5)==item.node_id.substring(0,5)" />
        </li>
    </ul>
  </div>
</template>

<script>
import { mapState,mapMutations } from 'vuex';
import util from '@/utils/util'
import table from '@/api/table'
import { Dialog } from 'vant';
import actions from "@/shared/actions";
export default {
  name: 'store-message',
  components: {
      'empty-box':() => import('@/components/empty-box.vue')
  },
  filters: {},
  mixins: [],
  props: {},
  data () {
    return {
      show:false
    }
  },
  computed: {
    ...mapState(['boss','userStatus']),
  },
  watch: {},
  created () {
    this.$store.commit("updateIsShowTabBar",false)
    this.$store.commit("updateIsShowPickStore",false)
  },
  mounted () {
    actions.onGlobalStateChange((state) => {
      this.$store.commit("updateUserStatus",state.userStatus)
    }, true)
    if(localStorage.getItem('sub')==1){
      localStorage.setItem('sub',0)
      this.getShopList()
    }else{
      this.$router.go(-1);//返回上一层
    }

  },
  methods: {
    ...mapMutations(['updateBossPower','updateCursNodeId2','updateRenderBarList']),
    // 获取可查看门店列表
    getShopList(){
        table.getShopList().then(response=>{
            this.$store.commit("updateStoreList",response.data.list)
            // 判断之前是否选择过门店
            this.boss.storeList.forEach(item=>{
              console.log(item.node_id.substring(0,5))
              console.log(this.userStatus.curs_node_id_2.substring(0,5))
              if(this.userStatus.curs_node_id_2.substring(0,5)==item.node_id.substring(0,5)){
                  this.navAuthority()
              }
            })
            let _this=this
            setTimeout(function(){
               _this.show=true
            },500)
        })
    },
    // 切换门店
    chooseShopItem(item){
        table.changeNodeId({node_id:item.node_id}).then(response=>{
            if(response.data.result){
                this.updateCursNodeId2(item.node_id)
                this.updateBossPower(response.data.menu)
                this.navAuthority()
            }
        })
    },
    // vip时间和菜单权限判断
    navAuthority(){
      if(!this.authDeadline()){ return location.href=location.protocol+'//'+location.host+'/payIndex'}
      var renderBarList=util.navAuthority(this.boss.navTabBar,this.userStatus.boss_power)
      if(renderBarList.length>0){
        this.updateRenderBarList(renderBarList)
        this.boss.storeList.forEach(item=>{
          if(this.userStatus.curs_node_id_2.substring(0,5)==item.node_id.substring(0,5)){
            localStorage.setItem('shopNameTitle',item.shop_name)
            this.$router.push({
            	name:renderBarList[0].link
            })
          }
        })
      }else{
        Dialog.alert({
          title: '提示',
          message: '无权限,请联系管理员',
        }).then(() => {
          this.$router.go(-1);//返回上一层
        })
      }
    },
    // 期限
    authDeadline(){
      return util.getRestDay(this.userStatus.vip_2)
    }
  }
}
</script>

<style lang="scss" scoped>
  .store-message-wrap {
   .store-content{
        position:relative;
        margin:22px;
        padding:28px;
        background:#fff;
        border-radius:10px;
        line-height:1.5;
        .store-name{
            font-size:30px;
            color:#333;
        }
        .store-address{
            font-size:24px;
            color:#999;
        }
        .van-icon-checked{
            position:absolute;
            top:28px;
            right:28px;
            font-size:40px;
            color:#aed581;
        }
    }
  }
</style>
